import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import CustomLink from "../../components/link"
import perfiles from "../../../static/img/irstrat/perfiles.png"
import saul from "../../../static/img/irstrat/saul.png"
import rodrigo from "../../../static/img/irstrat/rodrigo.png"
import joven_exitoso from "../../../static/img/irstrat/joven_exitoso.jpeg"
import clientes from "../../../static/img/irstrat/clientes-1.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Clientes"/>
            <Banner title="Clientes"/>
            <section className="executive_area perfil">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="ex_content">
                                <h2>30 Emisores públicos en 3 continentes</h2>
                                <span className="sec_border"></span>
                                <p>
                                    Nuestros clientes perciben el valor de una estrecha relación con los mercados, la
                                    ejecución de un
                                    sólido gobierno corporativo, una oportuna inteligencia, y un mensaje perfectamente
                                    claro y
                                    estructurado que refleje fielmente sus fundamentales y plan de negocio; por lo que
                                    buscan obtener
                                    ventaja y posicionamiento de los mismos.
                                </p>
                                <p>La mayoría de nuestros clientes se han acercado a nosotros por recomendaciones de
                                    otros clientes, así
                                    como por nuestro prestigio, reconociendo el valor y la ventaja de nuestra amplia red
                                    de contactos
                                    institucionales en México y Estados Unidos, el especializado conocimiento
                                    financiero,
                                    regulatorio y en
                                    comunicaciones de nuestro equipo, y nuestro amplio portafolio de servicios como la
                                    mejor opción para
                                    obtener los resultados que persiguen.</p>
                                <p>Gracias a nuestra constante entrega de valor y enfoque proactivo, nuestros clientes
                                    continúan
                                    expandiendo su relación de negocios con nosotros, a la par de recomendarnos con
                                    nuevos clientes de su
                                    círculo empresarial; haciendo así de IRStrat la consultoría mexicana de mayor
                                    crecimiento en su
                                    sector.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.1s" data-wow-duration="1.0s">
                            <img src={perfiles} alt="Perfiles"/>
                        </div>

                    </div>
                </div>
            </section>

            <section className="pt-5 pb-5 porque">

            </section>

            <section className="position-relative">
                <div className="container">
                    <div className="section_title black_c ">
                        <h2 className="mt-4">Testimonios</h2>
                        <span className="sec_border"/>
                    </div>
                </div>
                <div className="container">
                    <div id="testimonial" className="testimonial_slider owl-carousel testimonial_porque">
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={saul} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"Gracias a las soluciones de IRStrat, hemos
                                                podido mejorar la calidad de nuestro mensaje y
                                                ampliar nuestra visibilidad ante un número mayor de inversionistas
                                                institucionales nacionales y extranjeros, estrechar
                                                nuestra relación con importantes instituciones financieras nacionales, y
                                                mejorar el servicio a nuestra base de inversores,
                                                a través del apuntalamiento de la calidad de nuestros materiales de RI.
                                                En el transcurso de nuestra operación hemos
                                                trabajado con otras agencias; por lo que tenemos un marco de referencia
                                                bastante claro, y sin duda podemos afirmar que
                                                las soluciones que nos ha brindado IRStrat están a la altura de los más
                                                altos estándares internacionales, con tiempos
                                                de respuesta de extrema agilidad, siendo sus servicios de alto impacto
                                                en creación de valor."</em></p>
                                            <p className="text-right">
                                                <small><b>Saúl Castañeda<br></br>
                                                    Director de Administración y Finanzas en Grupo Industrial Saltillo
                                                    (GIS)</b></small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={rodrigo} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"En Maxcom estamos muy complacidos con la suite
                                                de servicios que nos ha brindado IRStrat, han apuntalado de manera
                                                significativa nuestro delivery of message. En el caso particular de
                                                nuestro Sitio de RI, llevaron a cabo su desarrollo
                                                de la A la Z (contenido, identidad gráfica y plataforma tecnológica), en
                                                un tiempo record, superando nuestras expectativas
                                                de calidad y servicio. Recibimos múltiples felicitaciones de parte de
                                                nuestros inversionistas por la óptima calidad de nuestro
                                                nuevo sitio de RI, sinceramente considero que IRStrat es la mejor opción
                                                en cuanto a asesoría de relaciones con inversionistas en México."</em>
                                            </p>
                                            <p className="text-right">
                                                <small><b>Rodrigo Wright<br></br>
                                                    Planificación de Negocios Financieros, Director de Tesorería y
                                                    Relación con Inversionistas en Maxcom Telecomunicaciones</b></small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={joven_exitoso} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"IRStrat nos brindó su apoyo para el desarrollo
                                                e implementación de
                                                políticas de operaciones con valores para consejeros y directivos, ello
                                                nos permitió mejorar nuestras prácticas en
                                                gobierno corporativo. IRStrat cumplió satisfactoriamente con nuestras
                                                expectativas tanto en tiempos como en calidad del
                                                servicio."</em></p>
                                            <p className="text-right">
                                                <small><b>Jonathan Rangel<br></br>
                                                    Director de Relación con Inversionistas en Crédito Real</b></small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about_consulting_area section_gap bg-white">
                <div className="container">
                    <div className="row strategy_info d-flex align-items-center">
                        <div className="col-lg-5">
                            <img className="video_img wow fadeIn" src={clientes} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h2>Solicitud de información</h2>
                                <span className="sec_border"></span>

                                <p>Nuestro equipo ha participado en todo el espectro de soluciones de
                                    relaciones con
                                    inversionistas, comunicación estratégica, PR y lobbying financiero, mercado de deuda
                                    y capitales, y
                                    prospección de inversionistas.</p>
                                <p>Para un listado de nuestros clientes y referencias, así como para conducir un
                                    diagnóstico de su programa de relaciones con inversionistas, por favor
                                    contáctenos.</p>
                                <CustomLink to="/contacto"
                                            className="get_btn btn_hover hover_color">Contáctenos</CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
